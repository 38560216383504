import request from "./request";

// export const search_email_apply = (params) => {
//     return request({
//         method: 'GET',
//         url: '/mr/miner/search_email_apply',
//         params
//     })
// }
export const query = (data) => {
  return request({
    method: "POST",
    url: "/q/query",
    data,
  });
};
export const queryMemo = (params) => {
  return request({
    method: "GET",
    url: "/q/queryorder",
    params,
  });
};
