import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import top from "../views/top.vue";
import h5Home from "../views/h5Home.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/top",
    name: "top",
    component: top,
  },
  {
    path: "/h5Home",
    name: "h5Home",
    component: h5Home,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
