import axios from "axios";
// import { Message } from "element-ui";
// import { b64EncodeUnicode } from "./util.js";

// import {
//   setToken,
//   getToken,
//   removeToken,
//   removeAccessToken,
// } from "@/utils/auth";
// import Qs from "qs";
import { removeToken } from "./auth";
import { ElNotification } from "element-plus";
// import { el } from "element-plus/es/locale";

const service = axios.create({
  timeout: 500000000, // request timeout
  // timeout: 3000, // request timeout
  // baseURL: 'http://154.31.41.124:9095',
  // baseURL: 'http://recruitment.dmctech.io:9095'
});
// request interceptor
service.interceptors.request.use(
  (config) => {
    // if (config.url.indexOf("/login") > -1) {
    //   config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    //   config.data = Qs.stringify(config.data);
    // } else {
    if (config.url == "/mr/miner/upload_dmc_acc") {
      config.headers["Content-Type"] = "multipart/form-data";
    }

    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  async (response) => {
    // console.log(response, "response");
    const res = response.data;

    if (
      response.code === 200 ||
      response.code === 201 ||
      response.code === 202
    ) {
      return res;
    }
    let code = res && res.code;
    if (
      response.config.url.indexOf("/x/asset") > -1 &&
      response.code === 200 &&
      code === 400
    ) {
      return res;
    }
    if (code && code !== 200) {
      ElNotification({
        type: "error",
        message: res.message || res.error || "error",
        position: "bottom-left",
      });
      if (code === 401 || code === 403) {
        removeToken();
        // removeAccessToken();
        // router.push("/login");

        // Message({
        //   message: res.error || "Error",
        //   type: "error",
        //   duration: 5 * 1000,
        // });
        return;
      } else if (code === 420) {
        removeToken();
        return;
      } else {
        return Promise.reject(res);
      }
    }
    if (response.status === 401 || response.status === 403) {
      removeToken();
      //   removeAccessToken();

      return;
    }
    if (response.status === 400) {
      // let errors = "";
      // if (response.errors && response.errors[0] && response.errors[0].detail) {
      //   errors = response.errors[0].detail;
      // }
      //   Message({
      //     message: errors || "Error",
      //     type: "error",
      //     duration: 5 * 1000,
      //   });
      return;
    }
    if (response.status === 204) {
      return res;
    }
    if (response.status !== 200) {
      //   Message({
      //     message: res.message || "Error",
      //     type: "error",
      //     duration: 5 * 1000,
      //   });
      return Promise.reject(new Error(res.message || "Error"));
    } else if (response.status === 200) {
      return res;
    }
  },
  (error) => {
    if (!(error.config && error.config.url.indexOf("ping"))) {
      //   Message({
      //     message: error.message,
      //     type: "error",
      //     duration: 5 * 1000,
      //   });
    }

    return Promise.reject(error);
  }
);

// function b64EncodeUnicode(str) {
//   return btoa(
//     encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
//       return String.fromCharCode("0x" + p1);
//     })
//   );
// }

export default service;
