// import Cookies from "js-cookie";

const TokenKey = "foggie-token";
// const TokenKey = "FoggieV_token";
const RefreshKey = "refresh_token";



export function getToken() {
  return window.localStorage.getItem(TokenKey);
  // return Cookies.get(TokenKey);
}

export function setToken(token) {
  window.localStorage.setItem(TokenKey, token);
  // return Cookies.set(TokenKey, token);
}

export function removeToken() {
  window.localStorage.removeItem(TokenKey);
  window.localStorage.removeItem(RefreshKey);
}

